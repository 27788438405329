import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../../state/actions'
import * as storage from '../../../utils/storage'
import { Link } from 'react-router'

import {
    Alert
} from 'react-bootstrap'

import {
    Container,
    Form,
    FormGroup,
    ControlLabel
} from './styles'

import { Translate } from 'react-localize-redux'

class GlobalConfig extends Component {
    constructor(props) {
        super(props)

        this.handleChangeBot = this.handleChangeBot.bind(this)
        this.handleChangeWit = this.handleChangeWit.bind(this)
    }

    componentDidMount() {
        return this.getGlobalConfig()
    }

    async getGlobalConfig() {
        try {
            await this.props.actions.getGlobalConfig(this.props.codename)
            const storageBotSelected = storage.get('botSelected')
            const botSelected = this.props.globalConfig && this.props.globalConfig.length && ((storageBotSelected && this.props.globalConfig.find(config => config.bot === storageBotSelected)) || this.props.globalConfig[0]) || null
            if (botSelected) {
                this.props.actions.selectBot(botSelected)
                const storageWitSelected = storage.get('witSelected')
                const witSelected = botSelected.languages && botSelected.languages.length && ((storageWitSelected && botSelected.languages.find(language => language.language === storageWitSelected)) || botSelected.languages.find(language => language.setup) || botSelected.languages[0]) || null
                if (witSelected) {
                    this.props.actions.selectWit(witSelected)
                }
            }
        } catch (error) {
            // ...
        }
    }

    handleChangeBot(e) {
        const value = e.target.value
        const botSelected = this.props.globalConfig.find(config => config.bot === value)
        if (botSelected) {
            this.props.actions.selectBot(botSelected)
            const storageWitSelected = storage.get('witSelected')
            const witSelected = botSelected.languages && botSelected.languages.length && ((storageWitSelected && botSelected.languages.find(language => language.language === storageWitSelected)) || botSelected.languages.find(language => language.setup) || botSelected.languages[0]) || null
            if (witSelected) {
                this.props.actions.selectWit(witSelected)
            }
        }
    }

    handleChangeWit(e) {
        const value = e.target.value
        const witSelected = this.props.botSelected.languages.find(language => language.language === value)
        this.props.actions.selectWit(witSelected)
    }

    render() {
        const { app, routing, globalConfig, botSelected, witSelected } = this.props
        const enabledPages = [
            '/ai',
            '/locale'
        ]
        const disabledPages = [
            '/ai/condition/',
            '/ai/unknownexpression'
        ]

        let warning = ''
        if (!botSelected) {
            warning = 'missing.bot'
        } else if (!witSelected) {
            warning = 'missing.language'
        } else if (witSelected && !witSelected.setup) {
            warning = 'setup'
        }

        let display = 'none'
        if (
            app &&
            app.codename &&
            routing &&
            routing.locationBeforeTransitions &&
            routing.locationBeforeTransitions.pathname &&
            enabledPages.find(enabledPage => routing.locationBeforeTransitions.pathname.startsWith(`/manager/${app.codename}${enabledPage}`)) &&
            !disabledPages.find(disabledPage => routing.locationBeforeTransitions.pathname.startsWith(`/manager/${app.codename}${disabledPage}`))
        ) {
            if (globalConfig && globalConfig.length) {
                if (globalConfig.length > 1) {
                    display = 'block'
                } else if (globalConfig[0].languages && globalConfig[0].languages.length > 1) {
                    display = 'block'
                }
            } else if (witSelected && !witSelected.setup) {
                display = 'block'
            }
        }

        return (
            <Container display={display}>
                <Form>
                    {(globalConfig && globalConfig.length) ? (
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="globalConfig.bot" />
                            </ControlLabel>
                            <select
                                key={`botSelected_${botSelected && botSelected.name || 'none'}`}
                                name="bot"
                                value={botSelected && botSelected.bot || undefined}
                                onChange={this.handleChangeBot}
                            >
                                {globalConfig.map((config, index) => (
                                    <option key={`${config.name}_${index}`} value={config.bot}>{config.name}</option>
                                ))}
                            </select>
                        </FormGroup>
                    ) : ''}
                    {(botSelected && botSelected.languages && botSelected.languages.length) ? (
                        <FormGroup>
                            <ControlLabel>
                                <Translate id="globalConfig.language" />
                            </ControlLabel>
                            <select
                                key={`witSelected_${witSelected && witSelected.language || 'none'}`}
                                name="wit"
                                value={witSelected && witSelected.language || undefined}
                                onChange={this.handleChangeWit}
                            >
                                {botSelected.languages.map((language, index) => (
                                    <option key={`${language.language}_${index}`} value={language.language}>{language.name}</option>
                                ))}
                            </select>
                        </FormGroup>
                    ) : ''}
                </Form>
                {warning ? (
                    <Alert bsStyle="warning" style={{ marginTop: '15px', marginBottom: '0px' }}>
                        <Translate id={`globalConfig.${warning}`} />
                    </Alert>
                ) : ''}
            </Container>
        )
    }
}

function mapStateToProps(state) {
    return {
        app: state.app,
        routing: state.routing,
        globalConfig: state.globalConfig,
        botSelected: state.botSelected,
        witSelected: state.witSelected
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(GlobalConfig)
