import * as actions from '../actions'

const defaultState = false

export default function isReady(state = defaultState, { type, payload }) {
    switch (type) {
        case actions.IS_READY:
            return true
        case actions.LOGIN_USER:
            return Boolean(payload.data)
        default:
            return state
    }
}
