import React, { Component } from 'react'

// THIS COMPONENT IS ALMOST USELESS
// EXCEPT IT STOPS A STUPID DOM ERROR TO SHOW UP EVERY TIME THE MANAGER LOADS UP ON DEV
// DO NOT REMOVE IT!
class Sidebar extends Component {
    render() {
        return (
            <div className="navbar-default sidebar" role="navigation">
                { this.props.children }
            </div>
        )
    }
}

export default Sidebar
