import * as actions from '../actions'

const defaultState = null

export default function hsmTemplate(state = defaultState, action) {
    switch (action.type) {
        case actions.GET_HSM_TEMPLATE:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.CLEAN_HSM_TEMPLATES:
            return defaultState

        default:
            return state
    }
}
