import account from './account'
import accounts from './accounts'
import api from './api'
import app from './app'
import apps from './apps'
import billing from './billing'
import bots from './bots'
import botSelected from './botSelected'
import cache from './cache'
import condition from './ai/condition'
import conditions from './ai/conditions'
import conversation from './conversation'
import conversations from './conversations'
import customerService from './customerService'
import entities from './ai/entities'
import globalConfig from './globalConfig'
import globalLimit from './globalLimit'
import globalSkip from './globalSkip'
import hsm from './hsm'
import hsmTemplate from './hsmTemplate'
import hsmTemplates from './hsmTemplates'
import isReady from './isReady'
import bullStats from './bullStats'
import botsStats from './botsStats'
import locales from './locales'
import menu from './menu'
import message from './message'
import messages from './messages'
import messageUser from './messageUser'
import messageUsers from './messageUsers'
import previousPath from './previousPath'
import profile from './profile'
import publicFlows from './publicFlows'
import resource from './resource'
import resourceAll from './resourceAll'
import resourceById from './resourceById'
import resourceDeletedMedia from './resourceDeletedMedia'
import resourceUploadMedia from './resourceUploadMedia'
import { routerReducer as routing } from 'react-router-redux'
import samples from './ai/samples'
import saveAssetForFacebook from './saveAssetForFacebook'
import saveAssetForTelegram from './saveAssetForTelegram'
import statusCT from './statusCT'
import task from './ai/task'
import taskExpressions from './ai/tasks'
import tasks from './ai/nlp'
import unknownCategory from './unknownCategory'
import unknownConversations from './unknownConversations'
import unknownExpressions from './unknownExpressions'
import unknownFilters from './unknownFilters'
import uptimeStats from './uptimeStats'
import user from './user'
import users from './users'
import webhook from './webhook'
import webhooks from './webhooks'
import witSelected from './ai/witSelected'
import witStatus from './ai/witStatus'

export default {
    account,
    accounts,
    api,
    app,
    apps,
    billing,
    bots,
    botSelected,
    cache,
    condition,
    conditions,
    conversation,
    conversations,
    customerService,
    entities,
    globalConfig,
    globalLimit,
    globalSkip,
    hsm,
    hsmTemplate,
    hsmTemplates,
    isReady,
    bullStats,
    botsStats,
    locales,
    menu,
    message,
    messages,
    messageUser,
    messageUsers,
    previousPath,
    profile,
    publicFlows,
    resource,
    resourceAll,
    resourceById,
    resourceDeletedMedia,
    resourceUploadMedia,
    routing,
    samples,
    saveAssetForFacebook,
    saveAssetForTelegram,
    statusCT,
    task,
    taskExpressions,
    tasks,
    unknownCategory,
    unknownConversations,
    unknownExpressions,
    unknownFilters,
    uptimeStats,
    user,
    users,
    webhook,
    webhooks,
    witSelected,
    witStatus
}
