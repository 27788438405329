import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinkit from 'react-spinkit'

class Spinner extends Component {
    render() {
        const self = this
        return (
            <Spinkit
                name={self.props.name || 'line-scale'}
                fadeIn={self.props.fadeIn || 'quarter'}
                style={self.props.style || { 'textAlign': 'center', 'padding': '10px 0px', 'color': self.props.color || '#000' }}
            />
        )
    }
}

Spinner.propTypes = {
    name: PropTypes.string,
    fadeIn: PropTypes.string,
    style: PropTypes.object,
    color: PropTypes.string
}

export default Spinner
