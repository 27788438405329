import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../state/actions'
import Spinner from '../../components/common/Spinner'
import Navigation from '../../components/Navigation'
import GlobalConfig from '../../components/common/GlobalConfig'
import Tab from '../../components/common/Tab'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate } from 'react-localize-redux'
import './styles.less'
import Sidebar from './sidebar'

import '../../less/mt.less'
import '../../less/sbadmin2/sb-admin-2.less'

class Manager extends Component {
    constructor(props) {
        super(props)
        this.state = {}
        this.cleanSkip = this.props.actions.cleanSkip.bind(this)
    }

    async componentDidMount() {
        const self = this
        if (!self.props.profile) {
            await self.props.actions.userGetProfile()
        }
        await self.props.actions.appGetOneCodename(self.props.params.appCodename)
        await self.props.actions.getMenuConfig(self.props.params.appCodename)
        await self.props.actions.getBilling(self.props.params.appCodename)
        if (self.props.app && !self.props.app.deletedAt) {
            if (self.props.profile && self.props.profile.profile >= 1000) {
                await self.props.actions.accountGet(self.props.params.appCodename)
            }
        }
    }

    cleanQueryAction() {
        this.setState({ hasQueryAction: false })
    }

    render () {
        const { app, menu, profile, isReady, routing, botSelected, statusCT } = this.props
        if (!(isReady && profile && app && menu)) {
            return (
                <Spinner />
            )
        } else {
            const botAIEnabled = botSelected && botSelected.menu && botSelected.menu.ai
            const botOverlayLocalesEnabled = botSelected && botSelected.menu && botSelected.menu.locales
            const botUnknownExpressionsEnabled = botSelected && botSelected.menu && botSelected.menu.unknownExpressions
            const botConditionsEnabled = botSelected && botSelected.menu && botSelected.menu.conditions
            const botNPSEnabled = botSelected && botSelected.menu && botSelected.menu.nps

            const reports = {
                collapse: true,
                name: 'menu.reports',
                icon: 'filter',
                urls: [
                    {
                        url: '/reports/metrics',
                        name: 'menu.metrics',
                        condition: menu.reports.metrics
                    },
                    {
                        url: '/reports/bot',
                        name: 'menu.botEfficiency',
                        condition: menu.reports.bot
                    },
                    {
                        url: '/reports/ai',
                        name: 'menu.aiStats',
                        condition: menu.reports.ai && botAIEnabled
                    },
                    {
                        url: '/reports/nps',
                        name: 'menu.nps',
                        condition: menu.reports.nps && botNPSEnabled && botNPSEnabled.enabled && botNPSEnabled.system === 'nps'
                    },
                    {
                        url: '/reports/csat',
                        name: 'menu.csat',
                        condition: menu.reports.nps && botNPSEnabled && botNPSEnabled.enabled && botNPSEnabled.system === 'c-sat'
                    },
                    {
                        url: `/operator`,
                        name: 'menu.operators',
                        icon: 'user-astronaut',
                        condition: menu.customerservice
                    },
                    {
                        url: '/ai/unknownexpression',
                        name: 'menu.unknownExpression',
                        condition: menu.reports.unknownExpression && botUnknownExpressionsEnabled
                    }
                ]
            }

            if (menu.reports.googlelookerstudio && Array.isArray(menu.reports.googlelookerstudio)) {
                menu.reports.googlelookerstudio.forEach((googlelookerstudioReport, index) => {
                    reports.urls.push({
                        url: `/report/googlelookerstudio?report=${googlelookerstudioReport.name}`,
                        nameLiteral: googlelookerstudioReport.name,
                        condition: true
                    })
                })
            }

            if (menu.reports.leads && Array.isArray(menu.reports.leads)) {
                menu.reports.leads.forEach((leadsReport, index) => {
                    if (leadsReport && leadsReport.name && leadsReport.url) {
                        reports.urls.push({
                            url: `/report/leads?report=${leadsReport.name}`,
                            nameLiteral: leadsReport.name,
                            condition: true
                        })
                    }
                })
            }

            const urls = [
                {
                    url: '/dashboard',
                    name: 'menu.dashboard',
                    icon: 'tachometer-alt',
                    condition: menu.dashboard
                },
                {
                    url: '/conversation',
                    name: 'menu.conversations',
                    icon: 'comments',
                    condition: menu.conversations
                },
                {
                    custom: true,
                    component: (key) => (
                        <li key={key}>
                            <a href={process.env.CUSTOMERSERVICE_URL.toString()} target="__blank">
                                <FontAwesomeIcon
                                    icon="handshake"
                                />
                                &nbsp;&nbsp;
                                <Translate id="menu.customerservice" />
                            </a>
                        </li>
                    ),
                    condition: menu.customerservice
                },
                {
                    collapse: true,
                    name: 'menu.ai',
                    icon: 'brain',
                    urls: [
                        {
                            url: '/ai/train',
                            name: 'menu.train',
                            condition: menu.ai.train && botAIEnabled
                        }, {
                            url: '/ai/task',
                            name: 'menu.task',
                            condition: menu.ai.task && botAIEnabled && profile.profile === 2000
                        }, {
                            url: '/ai/entities',
                            name: 'menu.entities',
                            condition: menu.ai.entities && botAIEnabled && profile.profile === 2000
                        }, {
                            url: '/ai/condition',
                            name: 'menu.conditions',
                            condition: menu.ai.conditions && botAIEnabled && botConditionsEnabled && profile.profile === 2000
                        },
                        {
                            url: '/ai/status',
                            name: 'menu.status',
                            condition: menu.ai.status && botAIEnabled && profile.profile === 2000
                        }
                    ]
                },
                {
                    collapse: true,
                    name: 'menu.whatsapp',
                    icon: ['fab', 'whatsapp'],
                    urls: [
                        {
                            url: '/hsm',
                            name: 'menu.hsms',
                            condition: menu.whatsapp.hsms
                        }, {
                            url: '/hsm/template',
                            name: 'menu.hsmTemplates',
                            condition: menu.whatsapp.hsmTemplates
                        }
                    ]
                },
                reports,
                {
                    url: '/support',
                    name: 'menu.support',
                    icon: 'headset',
                    condition: true
                },
                {
                    url: '/integrations',
                    name: 'menu.integrations',
                    icon: 'puzzle-piece',
                    condition: menu.integrations
                },
                {
                    collapse: true,
                    name: 'menu.setup',
                    icon: 'cog',
                    urls: [
                        {
                            url: '/user',
                            name: 'menu.users',
                            condition: menu.setup.users
                        },
                        {
                            url: '/resources',
                            name: 'menu.resources',
                            condition: menu.setup.resources && profile.profile === 2000
                        },
                        {
                            url: '/locale',
                            name: 'menu.locales',
                            condition: botOverlayLocalesEnabled
                        },
                        {
                            url: '/release',
                            name: 'menu.release',
                            icon: 'code-branch',
                            condition: menu.setup.release && profile.profile === 2000
                        }
                    ]
                }
            ]

            const wrapperStyle = (routing && routing.locationBeforeTransitions && routing.locationBeforeTransitions.pathname.match(/conversation/)) ? { paddingLeft: '0px', paddingRight: '0px' } : {}

            return (
                <div id="wrapper">
                    <Navigation appCodename={this.props.params.appCodename}>
                        <Sidebar>
                            <div className="sidebar-nav navbar-collapse">
                                <ul className="nav" id="side-menu">
                                    {urls.map((url, index) => {
                                        if (url.custom) {
                                            return url.condition ? url.component(`tab_${index}`) : null
                                        } else {
                                            return (
                                                <Tab
                                                    key={`tab_${index}`}
                                                    tabIndex={`tab_${index}`}
                                                    baseUrl={`/manager/${this.props.params.appCodename}`}
                                                    url={url.url}
                                                    icon={url.icon}
                                                    name={url.name}
                                                    cleanSkip={this.cleanSkip}
                                                    condition={url.condition}
                                                    isSuperAdmin={profile.profile === 2000}
                                                    collapse={url.collapse}
                                                    childrens={url.urls}
                                                    alert={url.hasAlert}
                                                />
                                            )
                                        }
                                    })}
                                </ul>
                            </div>
                            <div style={{width: '100%', padding: '1em 2em'}}></div>
                            {
                                statusCT && statusCT.icon && <div className="sidebar-footer">
                                    <a href='https://status.chat-tonic.com' target='_blank'>
                                        {statusCT.description}
                                        &nbsp;&nbsp;
                                        <FontAwesomeIcon icon={statusCT.icon} style={{ color: statusCT.color }}/>
                                    </a>
                                </div>
                            }
                        </Sidebar>
                    </Navigation>
                    <div id="page-wrapper" style={wrapperStyle}>
                        <GlobalConfig
                            codename={this.props.params.appCodename}
                        />
                        {this.props.children}
                    </div>
                </div>
            )
        }
    }

}

function mapStateToProps(state) {
    return {
        app: state.app,
        menu: state.menu,
        profile: state.profile,
        isReady: state.isReady,
        routing: state.routing,
        statusCT: state.statusCT,
        botSelected: state.botSelected
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Manager)
