import * as actions from '../actions'

const defaultState = null

export default function conversation(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
        case actions.APP_SELECT:
        case actions.CLEAN_CONVERSATION:
            return defaultState

        case actions.GET_CONVERSATION:
            if (!action.error) {
                state = action.payload.data
                state.webhooks = {}
            } else {
                state = {}
            }
            return state

        case actions.MESSAGE_RECEIVE:
            if (state && state._id === action.payload.messageUser) {
                state.lastMessageInByUserAt = action.payload.createdAt
            }
            return state

        case actions.GET_USER_WEBHOOK:
            if (!action.error) {
                state.webhooks = action.payload.data
            }
            return state

        case actions.PAUSE:
            if (!action.error) {
                state.pausedBot = {
                    from: new Date().toDateString()
                }
            }
            return state

        case actions.UNPAUSE:
            if (state && !action.error) {
                delete state.pausedBot
            }
            return state

        case actions.WEBHOOK_ACTION:
            if (state && state._id && action && action.payload && action.payload.data && action.payload.data.matched >= 1 && ['pause', 'unpase'].indexOf(action.payload.data.webhookAction) >= 0) {
                if (action.payload.data.webhookAction === 'pause') {
                    state.pausedBot = {
                        from: new Date()
                    }
                } else if (action.payload.data.webhookAction === 'unpause') {
                    delete state.pausedBot
                }
            }
            return state

        case actions.LOAD_CACHE:
            return Object.assign({}, state, action && action.payload && action.payload.conversation || {})

        default:
            return state
    }
}
