import * as actions from '../actions';

var defaultState = null;

export default function apps(state = defaultState, action) {
    switch (action.type) {

        case actions.APP_GET:
            var newState = state;
            if (!action.error) {
                newState = action.payload.data;
            }
            return newState;

        case actions.APP_CLEAN:
            return defaultState;

        default:
            return state;

    }
}
