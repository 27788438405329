import * as actions from '../../actions'
import findIndex from 'lodash/findIndex'

const defaultState = null

export default function tasks(state = defaultState, action) {
    switch (action.type) {
        case actions.GET_AI_TASKS:
        case actions.GET_AI_EXPRESSIONS:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.CLEAN_AI_TASKS:
        case actions.CLEAN_AI_EXPRESSIONS:
            return defaultState

        case actions.UPDATE_STATUS_AI_TASKS:
            let index = findIndex(state.objects, ['_id', action.payload.data._id])
            state.objects[index] = action.payload.data
            return state

        default:
            return state

    }
}
