import * as actions from '../actions'

var defaultState = null

export default function resourceById(state = defaultState, action) {
    switch (action.type) {
        case actions.RESOURCE_GET_ONE:
            var newState = state
            if (!action.error && action.payload.data && action.payload.data) {
                newState = action.payload.data
            }
            return newState
        case actions.RESOURCE_GET_ONE_CLEAN:
            return defaultState
        default:
            return state
    }
}
