import * as actions from '../actions'
import * as storage from '../../utils/storage'

const defaultState = null
const ignoreErrorsFromActions = [
    actions.UPTIME_STATS_GET
]

export default function api(state = defaultState, action) {
    switch (action.type) {
        case actions.API_CLEAN:
            return defaultState

        default:
            let newState = defaultState
            if (action && action.error && action.type && ignoreErrorsFromActions.indexOf(action.type) < 0) {
                newState = {}
                if (action.payload && action.payload.error && action.payload.error.response && action.payload.error.response.status && action.payload.error.response.status === 401) {
                    storage.remove(storage.accessTokenKey)
                }
                if (action.payload && action.payload.error && action.payload.error.data && action.payload.error.data.error) {
                    newState.error = action.payload.error.data.error
                } else if (action.payload.error) {
                    newState.error = action.payload.error
                } else {
                    newState.error = action.payload
                }
                if (!newState.error) {
                    newState.error = 'Unexpected error'
                }
            }
            return newState
    }
}
