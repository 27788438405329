import { createStore, combineReducers, applyMiddleware } from 'redux'
import promiseMiddleware from 'redux-promise'
import reducers from '../reducers'
import { localizeReducer } from 'react-localize-redux'
import * as actions from '../actions'

const rootReducer = combineReducers({
    localize: localizeReducer,
    ...reducers
})

const initialState = {}

const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(
        promiseMiddleware
    )
)

if (process.env.MT_ENV === 'development') {
    let previousState = {}
    store.subscribe(() => {
        const currentState = store.getState()
        const diff = Object.keys(previousState).reduce((diff, key) => {
            if (currentState[key] === previousState[key]) return diff
            return {
                ...diff,
                [key]: previousState[key]
            }
        }, {})
        previousState = currentState
        // console.log('State changed: ', diff, currentState)
    })
}

export default store
