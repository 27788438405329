import * as actions from '../../actions'

var defaultState = null

export default function condition(state = defaultState, action) {
    switch (action.type) {

        case actions.GET_AI_CONDITION:
            var newState = state
            if (!action.error) {
                newState = action.payload.data
            }
            return newState

        case actions.CLEAN_AI_CONDITION:
            return defaultState

        default:
            return state

    }
}
