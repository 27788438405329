import * as actions from '../actions'
import moment from 'moment'

const defaultState = []

export default function conversations(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
        case actions.APP_SELECT:
        case actions.CLEAN_CONVERSATIONS:
            return defaultState

        case actions.GET_CONVERSATIONS:
            if (!action.error) {
                if (action.payload.data.skip) {
                    state = state.concat(action.payload.data.objects)
                } else {
                    state = action.payload.data.objects
                }
            }
            return state

        case actions.MESSAGE_RECEIVE:
            state = state.map(conversation => {
                if (conversation._id === action.payload.messageUser) {
                    conversation.lastMessageInByUserAt = action.payload.createdAt
                }
                return conversation
            })
            return state

        case actions.PAUSE:
            if (!action.error) {
                const conversationId = action.payload.config && action.payload.config.params && action.payload.config.params.conversation
                if (conversationId) {
                    state = state.map(conversation => {
                        if (conversation._id === conversationId) {
                            conversation.pausedBot = {
                                from: new Date().toDateString()
                            }
                        }
                        return conversation
                    })
                }
            }
            return state

        case actions.UNPAUSE:
            const conversationId = action.payload.config && action.payload.config.params && action.payload.config.params.conversation
            if (conversationId) {
                state = state.map(conversation => {
                    if (conversation._id === conversationId) {
                        delete conversation.pausedBot
                    }
                    return conversation
                })
            }
            return state

        default:
            return state
    }
}
