import styled from 'styled-components'

export const Container = styled.div`
    & > .navbar {
        border: 0px;
        border-radius: 0px;
        margin-bottom: 0px;
        background: ${props => props.isApp && props.theme && props.theme.colors.primary || '#152E3B'};

        & #nav-dropdown {
            color: white;
            font-size: 2rem;
        }

        & .open {
            & #nav-dropdown {
                background-color: #FFF;
                color: ${props => props.theme.colors.primary};
            }
        }

        & .divider {
            background-color: ${props => props.theme.colors.primary} !important;
        }

        & .dropdown-menu {
            & > li {
                padding: 5px 15px;
                & > a {
                    color: ${props => props.theme.colors.primary};
                }
            }
        }

        & .navbar-nav {
            margin: 0px -15px;
        }

        & .navbar-right {
            display: inline-flex;
            align-items: center;
        }

        & .sidebar {
            border-right: 1px solid ${props => props.theme.menu && props.theme.menu.border || '#D4D6D6'};
        }

        & .sidebar ul li {
            border-bottom: 1px solid ${props => props.theme.menu && props.theme.menu.border || '#D4D6D6'};
            a {
                color: ${props => props.theme.menu && props.theme.menu.text || '#152E3B'};
                &:hover {
                    background-color: ${props => props.theme.menu && props.theme.menu.selected || '#F4D345'} !important;
                }
                &.active {
                    background-color: ${props => props.theme.menu && props.theme.menu.selected || '#F4D345'};
                }
            }
        }

        @media(max-width: 767px) {
            & #nav-dropdown {
                background-color: #FFF;
                color: #777;
            }

            & .dropdown-menu {
                background-color: #FFF !important;
                & > li {
                    & > a, a:hover {
                        color: ${props => props.theme.menu && props.theme.menu.text || '#152E3B'} !important;
                    }

                    &:hover {
                        background-color: ${props => props.theme.menu && props.theme.menu.selected || '#F4D345'} !important;
                    }
                }

            }

            & .navbar-toggle {
                background-color: #FFF !important;
                & > .icon-bar {
                    background-color: ${props => props.theme.colors.primary} !important;
                }
            }

            & .brand {
                margin: auto;
            }
        }
    }
`

export const Logo = styled.div`
    background-image: url(${props => props.isApp && props.theme && props.theme.logo || '/manager/static/images/chat-tonic-negativo-2.png'});
    width: 100px;
    height: 50px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-left: 10px !important;
`
export const ButtonAlert = styled.button`
    background-color: #FF0000;
    border: none;
    margin: 3px;
    padding-left: 12px;
`

export const Alert = styled.div`
    color: white;
`
