import * as actions from '../actions'

const defaultState = null

export default function messageUsers(state = defaultState, action) {
    switch (action.type) {
        case actions.UNKNOWN_CONVERSATION_GET:
            let newState = state
            if (!action.error) {
                newState = action.payload.data
            }
            return newState

        case actions.UNKNOWN_CONVERSATION_CLEAN:
            return defaultState

        default:
            return state
    }
}
