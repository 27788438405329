import * as actions from '../../actions'

const defaultState = []

export default function conditions(state = defaultState, action) {
    switch (action.type) {
        case actions.GET_AI_CONDITIONS:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.CLEAN_AI_CONDITIONS:
            return defaultState

        case actions.DISABLE_AI_CONDITION:
            const { conditionId, data } = action.payload
            if (conditionId && data) {
                const objects = state.objects.map(condition => {
                    if (condition._id === conditionId) {
                        condition = Object.assign({}, condition, data)
                    }
                    return condition
                })

                return Object.assign({}, state, { objects })
            } else {
                return state
            }

        default:
            return state
    }
}
