import styled from 'styled-components'

export const Container = styled.div`
    display: ${props => props.display};
    width: 100%;
    background-color: #27CCC3;
    color: #FFF;
    margin-top: 10px;
    padding: 15px;
    border-radius: 3px;
`

export const Form = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`

export const FormGroup = styled.div`
    display: flex;
    align-items: center;
    & > select {
        width: auto;
        color: #000;
        border: none;
        border-radius: 3px;
        padding: 2px 5px;
        &:focus {
            outline: 0;
        }
    }
`

export const ControlLabel = styled.span`
    font-weight: bold;
    margin-right: 5px;
`
