import * as actions from '../../actions'

const defaultState = null

export default function tasks(state = defaultState, action) {
    switch (action.type) {
        case actions.GET_AI_TASK:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.CLEAN_AI_TASK:
            return defaultState

        default:
            return state
    }
}
