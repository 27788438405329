import * as actions from '../actions'

var defaultState = null

export default function resource(state = defaultState, action) {
    switch (action.type) {

        case actions.RESOURCE_ADD:
            var newState = state
            if (!action.error) {
                newState = defaultState
            }
            return newState

        case actions.RESOURCE_DELETE:
            if (!action.error) {
                newState = defaultState
            }
            return newState

        default:
            return state

    }
}
