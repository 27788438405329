import * as actions from '../../actions'

const defaultState = null

export default function samples(state = defaultState, action) {
    switch (action.type) {
        case actions.GET_AI_SAMPLES:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.DELETE_AI_SAMPLES:
            if (!action.error && state && state.objects) {
                state.objects = state.objects.filter(sample => sample.id !== action.payload.data.id)
            }
            return state

        case actions.CLEAN_AI_SAMPLES:
            return defaultState

        default:
            return state
    }
}
