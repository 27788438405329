import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { browserHistory } from 'react-router'
import { Alert } from 'react-bootstrap'
import { ThemeProvider } from 'styled-components'
import themes from '../../styles/themes'
import * as actions from '../../state/actions'
import * as storage from '../../utils/storage'
import style from './styles.less'

import { renderToStaticMarkup } from 'react-dom/server'
import { withLocalize } from 'react-localize-redux'
import globalTranslations from '../../translations/global.json'
import { Helmet } from 'react-helmet'
import { ToastContainer } from 'react-toastify'

class Base extends Component {
    constructor(props) {
        super(props)

        const languages = [
            { name: 'Español', code: 'es' },
            { name: 'English', code: 'en' }
        ]

        let navLanguage = navigator.language || navigator.userLanguage
        navLanguage = navLanguage.split('-')[0]

        if (!languages.find(language => language.code === navLanguage)) {
            navLanguage = 'es'
        }

        this.props.initialize({
            languages,
            translation: globalTranslations,
            options: {
                defaultLanguage: storage.get('language') || navLanguage,
                renderToStaticMarkup
            }
        })
        this.props.actions.previousPath(`${this.props.location.pathname}${this.props.location.search}`)
    }

    async componentDidMount() {
        const self = this
        try {
            if (!self.props.profile && storage.get(storage.accessTokenKey)) {
                await  self.props.actions.userGetProfile()
            }
            self.props.actions.isReady()
        } catch (error) {
            browserHistory.push('/manager/login')
        }
        try {
            self.checkStatusPage()
            if(!self.refreshCheckScript && !self.refreshCheckStatus){
                self.refreshCheckScript = setInterval(() => {
                    self.checkStatusPage()
                }, 60000) // 1'
            }
        } catch {
            // do nothing
        }
    }

    componentWillUnmount() {
        const self = this
        if (self.refreshCheckStatus) {
            clearInterval(self.refreshCheckStatus)
        }
        if(self.refreshCheckScript){
            clearInterval(self.refreshCheckScript)
        }
    }

    checkStatusPage(){
        const self = this
        try{
            if(StatusPage){
                if(self.refreshCheckScript){
                    clearInterval(self.refreshCheckScript)
                }
                self.sp = new StatusPage.page({ page : '3s9bx3y3vn6t' })
                self.getStatusCT()
                self.refreshCheckStatus = setInterval(() => {
                    self.getStatusCT()
                }, 300000) // 5'
            }
        } catch{

        }
    }

    getStatusCT() {
        const self = this
        try{
            self.sp.status({
                success : data => {
                    let icon
                    let color
                    let description = data && data.status && data.status.description
                    switch (data && data.status && data.status.indicator) {
                        case 'critical':
                            color = 'red'
                            icon = 'times-circle'
                            break
                        case 'major':
                            color = 'orange'
                            icon = 'exclamation-circle'
                            break
                        case 'minor':
                            color = 'yellow'
                            icon = 'exclamation-circle'
                            break
                        case 'none':
                            color = 'green'
                            icon = 'check-circle'
                            break
                        default:
                            color = ''
                            icon = ''
                    }
                    self.props.actions.setStatusCT({
                        icon,
                        color,
                        description
                    })
                }
            })
        } catch{
            // err
        }
    }

    componentDidUpdate() {
        const { routing, isReady, profile, api } = this.props
        const location = routing && routing.locationBeforeTransitions
        const errorStatus = api && api.error && api.error.response && api.error.response.status && api.error.response.status

        if (isReady && !profile && location && location.pathname !== '/manager/login') {
            browserHistory.push('/manager/login')
        } else if (isReady && profile && location && (location.pathname === '/manager/login' || location.pathname === '/manager' || location.pathname === '/manager/')) {
            if (profile && profile.profile) {
                if (profile.profile > 0 && profile.profile <= 1000) {
                    browserHistory.push(this.props.previousPath && !this.props.previousPath.match(/\/manager($|\/$|\/login|\/app|\/account|\/stats)/) ? this.props.previousPath : `/manager/${profile.app.codename}/dashboard`)
                } else if (profile.profile === 2000) {
                    browserHistory.push(this.props.previousPath && !this.props.previousPath.match(/\/manager($|\/$|\/login)/) ? this.props.previousPath : '/manager/app')
                } else {
                    browserHistory.push('/manager/login')
                }
            }
        }

        if (errorStatus === 401) {
            if (location && location.pathname !== '/manager/login') {
                browserHistory.push('/manager/login')
            }
        }
    }

    render() {
        const self = this
        let alert
        let theme = themes.default
        const { app, api, profile } = self.props
        if (app && app.theme) {
            theme = app.theme
        }
        theme.brand = {
            primary: '#428bca',
            success: '#5cb85c',
            info: '#5bc0de',
            warning: '#f0ad4e',
            danger: '#d9534f'
        }
        if (api && api.error && !(api.error.response && api.error.response.status && api.error.response.status === 401)) {
            let extraInfo1
            let extraInfo2
            if (api.error.detail) {
                extraInfo1 = <p>{api.error.detail}</p>
            }
            if (api.error.response && api.error.response.data && api.error.response.data.error) {
                extraInfo2 = (
                    <div>
                        <p className="error">{api.error.response.data.error.message || ''}</p>
                        <p className="error">{JSON.stringify(api.error.response.data.error.detail || '')}</p>
                        <p className="error">{(api.error.response.data.error.code && ('Code: ' + api.error.response.data.error.code)) || ''}</p>
                    </div>
                )
            }
            alert = (
                <div className={style.error}>
                    <Alert id="alertError" bsStyle="danger" onDismiss={self.props.actions.apiClean.bind(self)}>
                        <strong>{api.error.message}</strong>
                        {extraInfo1}
                        {extraInfo2}
                    </Alert>
                </div>
            )
        }
        return (
            <ThemeProvider theme={theme}>
                <div>
                    {
                        (profile && profile.profile) && (process.env.MT_ENV === 'production' || process.env.MT_ENV === 'staging') && (
                            <Helmet>
                                <script type="text/javascript" src="https://cdn.statuspage.io/se-v2.js"></script>
                            </Helmet>
                        )
                    }
                    {process.env.MT_ENV === 'development' && alert}
                    <ToastContainer />
                    {self.props.children}
                </div>
            </ThemeProvider>
        )
    }
}

function mapStateToProps(state) {
    return {
        api: state.api,
        app: state.app,
        isReady: state.isReady,
        profile: state.profile,
        routing: state.routing,
        previousPath: state.previousPath
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default withLocalize(connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Base))
