import * as actions from '../../actions'

const defaultState = {
    objects: [],
    updated: new Date()
}

export default function entities(state = defaultState, action) {
    switch (action.type) {
        case actions.GET_AI_ENTITIES:
            if (!action.error) {
                state.objects = action.payload.data.objects
            }
            state.updated = new Date()
            return state

        case actions.ADD_AI_ENTITY:
            if (!action.error) {
                state.objects = state.objects.concat(action.payload.data.object)
                state.updated = new Date()
            }
            return state

        case actions.EDIT_AI_ENTITY:
            if (!action.error) {
                state.objects = state.objects.map(object => object.name === action.payload.data.name ? action.payload.data.object : object)
                state.updated = new Date()
            }
            return state

        case actions.DELETE_AI_ENTITY:
            if (!action.error) {
                state.objects = state.objects.filter(object => object.name !== action.payload.data.name)
                state.updated = new Date()
            }
            return state

        case actions.ADD_AI_ENTITY_VALUE:
            if (!action.error) {
                state.objects = state.objects.map(object => object.name === action.payload.data.name ? action.payload.data.object : object)
                state.updated = new Date()
            }
            return state

        case actions.DELETE_AI_ENTITY_VALUE:
            if (!action.error) {
                state.objects = state.objects.map(object => {
                    if (object.name === action.payload.data.name) {
                        object.values = object.values.filter(v => v.value !== action.payload.data.value)
                    }
                    return object
                })
                state.updated = new Date()
            }
            return state

        case actions.ADD_AI_SYNONYM:
            if (!action.error) {
                state.objects = state.objects.map(object => object.name === action.payload.data.name ? action.payload.data.object : object)
                state.updated = new Date()
            }
            return state

        case actions.DELETE_AI_SYNONYM:
            if (!action.error) {
                state.objects = state.objects.map(object => {
                    if (object.name === action.payload.data.name) {
                        object.values = object.values.map(value => {
                            if (value.value === action.payload.data.value) {
                                value.expressions = value.expressions.filter(synonym => synonym !== action.payload.data.synonym)
                            }
                            return value
                        })
                    }
                    return object
                })
                state.updated = new Date()
            }
            return state

        case actions.ADD_AI_SAMPLES:
            if (!action.error && action.payload.data && action.payload.data.objects) {
                action.payload.data.objects.forEach(data => {
                    state.objects = state.objects.map(object => {
                        if (object.name === data.name) {
                            object.values = object.values.map(value => {
                                if (value.value === data.value && value.expressions.indexOf(data.synonym) < 0) {
                                    value.expressions.push(data.synonym)
                                }
                                return value
                            })
                        }
                        return object
                    })
                })
                state.updated = new Date()
            }
            return state

        case actions.CLEAN_ALL:
        case actions.APP_SELECT:
        case actions.CLEAN_AI_ENTITIES:
            state.objects = []
            state.updated = new Date()
            return state

        default:
            return state
    }
}
