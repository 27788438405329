import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as actions from '../../state/actions'
import { browserHistory } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate } from 'react-localize-redux'
import LanguageToggle from '../LanguageToggle'
import AlertNonPayment from '../../containers/AlertNonPayment'
import moment from 'moment'

import {
    Navbar,
    Nav,
    NavDropdown,
    MenuItem
} from 'react-bootstrap'

import {
    Alert,
    ButtonAlert,
    Container,
    Logo
} from './styles'

class Navigation extends Component {
    constructor(props) {
        super(props)

        this.state = {
            show: false
        }

        this.dropdownAction = this.dropdownAction.bind(this)
        this.openModal = this.openModal.bind(this)
        this.closeModal = this.closeModal.bind(this)
        this.cookieBillingAlert = this.cookieBillingAlert.bind(this)
    }

    openModal() {
        this.setState({
            show: true
        })
    }

    closeModal() {
        this.setState({
            show: false
        })
    }

    dropdownAction(value) {
        if (value === 'logout') {
            this.props.actions.userCleanProfile()
        } else if (value === 'perfil') {
            browserHistory.push({
                pathname: `/manager/${this.props.profile.app.codename}/user/edit/me`
            })
        } else if (value === 'apps') {
            browserHistory.push({
                pathname: '/manager/app'
            })
        }
    }

    cookieBillingAlert() {
        const self = this
        const { billing, profile } = self.props
        const { show } = self.state
        if ((billing && billing.billing && billing.billing.enabled) && !(document.cookie.split(';').some((item) => item.trim().startsWith('alert_billing'))) && profile && profile.profile !== 2000) {
            const startDate = moment().endOf('day').clone().format('ddd, DD MMM YYYY HH:mm:ss')
            document.cookie = `alert_billing=true; expires=${startDate}`
            if (!show) {
                self.setState({
                    show: true
                })
            }
        }
    }

    render() {
        const self = this
        const { profile, appCodename, billing } = self.props
        const { show } = self.state
        const goToProfile = (profile && profile.app) ? (
            <MenuItem eventKey="perfil">
                <Translate id="navigation.profile" data={{
                    icon: (<FontAwesomeIcon icon="home" />)
                }} />
            </MenuItem>
        ) : ''

        const goToApps = (profile && profile.profile === 2000) ? (
            <MenuItem eventKey="apps">
                <Translate id="navigation.apps" data={{
                    icon: (<FontAwesomeIcon icon="laptop" />)
                }} />
            </MenuItem>
        ) : ''

        let goToAlertBilling = ''
        if (billing && billing.billing && billing.billing.enabled) {
            self.cookieBillingAlert()
            goToAlertBilling = (
                <ButtonAlert onClick={self.openModal}>
                    <Alert>
                        <Translate id="alertNonPayment.billingNonpayment"/> <img src={`/manager/static/images/alert.png`} height={30}/>
                    </Alert>
                </ButtonAlert>
            )
        }

        return (
            <div>
                <Container isApp={appCodename}>
                    <Navbar fluid>
                        <Navbar.Header>
                            <Navbar.Brand>
                                <Logo isApp={appCodename}></Logo>
                            </Navbar.Brand>
                            <Navbar.Toggle />
                        </Navbar.Header>
                        <Navbar.Collapse>
                            <Nav>
                                {this.props.children}
                            </Nav>
                            <Nav pullRight>
                                {goToAlertBilling}
                                {this.props.alert}
                                <LanguageToggle />
                                <NavDropdown id="nav-dropdown" title={<FontAwesomeIcon icon="user-circle" />} onSelect={this.dropdownAction} pullRight>
                                    { goToApps }
                                    { goToProfile }
                                    <MenuItem eventKey="logout">
                                        <Translate id="navigation.logout" data={{
                                            icon: (<FontAwesomeIcon icon="sign-out-alt" />)
                                        }} />
                                    </MenuItem>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </Container>
                {show ? <AlertNonPayment
                    show={show}
                    closeModal={this.closeModal}
                /> : ''}
            </div>
        )
    }
}

Navigation.propTypes = {
    alert: PropTypes.any,
    onTitleClick: PropTypes.func,
    appCodename: PropTypes.string
}

function mapStateToProps(state) {
    return {
        billing: state.billing,
        app: state.app,
        profile: state.profile,
        routing: state.routing
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(actions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Navigation)
