import * as actions from '../actions'
import moment from 'moment'

const defaultState = {}

export default function cache(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
        case actions.APP_SELECT:
        case actions.CLEAN_CACHE:
            return defaultState

        case actions.SAVE_CACHE:
            state[action.payload.messageUserId] = action.payload.stores
            return state

        case actions.WEBHOOK_ACTION:
            if (action && action.payload && action.payload.data && action.payload.data.matched >= 1 && action.payload.data.messageUserId && ['pause', 'unpase'].indexOf(action.payload.data.webhookAction) >= 0) {
                Object.keys(state).forEach(messageUserId => {
                    if (state[messageUserId] && state[messageUserId].conversation && (state[messageUserId].conversation._id === action.payload.data.messageUserId)) {
                        if (action.payload.data.webhookAction === 'pause') {
                            state[messageUserId].conversation.pausedBot = {
                                from: new Date()
                            }
                        } else if (action.payload.data.webhookAction === 'unpause') {
                            delete state[messageUserId].conversation.pausedBot
                        }
                    }
                })
            }
            return state

        default:
            return state
    }
}
