import * as actions from '../actions'

var defaultState = null

export default function saveAssetForFacebook(state = defaultState, action) {
    switch (action.type) {
        case actions.SAVE_ASSET_FACEBOOK:
            var newState = state
            if (!action.error) {
                newState = defaultState
            }
            return newState
        case actions.CLEAN_SAVE_ASSET_FACEBOOK:
            return defaultState
        default:
            return state
    }
}
