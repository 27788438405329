import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Translate } from 'react-localize-redux'
import style from './styles.less'

import {
    Badge,
    Collapse
} from 'react-bootstrap'

class Tab extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }

        this.toggle = this.toggle.bind(this)
    }

    toggle() {
        this.setState({
            open: !this.state.open
        })
    }

    render() {
        const { tabIndex, baseUrl, url, icon, name, isSuperAdmin, collapse, childrens, alert } = this.props
        const { open } = this.state
        const liStyle = {}

        let condition = this.props.condition
        if (!condition && childrens) {
            condition = !!childrens.find(children => children.condition)
        }

        if (!condition) {
            if (isSuperAdmin) {
                liStyle.backgroundColor = '#EAEAEA'
            } else {
                return null
            }
        }

        if (collapse) {
            return (
                <li style={liStyle}>
                    <a className={style.collapse} onClick={this.toggle}>
                        <div>
                            <FontAwesomeIcon icon={icon} />&nbsp;&nbsp;<Translate id={name} />
                        </div>
                        <FontAwesomeIcon icon={`caret-${open ? 'up' : 'down'}`} />
                    </a>
                    <Collapse in={open}>
                        <ul className="nav nav-second-level">
                            {(childrens && childrens.length) ? childrens.map((children, index) => {
                                const childLiStyle = {}

                                if (!children.condition) {
                                    if (isSuperAdmin) {
                                        childLiStyle.backgroundColor = '#EAEAEA'
                                    } else {
                                        return null
                                    }
                                }

                                return (
                                    <li key={`${tabIndex}_children_${index}`} style={childLiStyle}>
                                        <Link className="menu" to={`${baseUrl}${children.url}`} activeClassName="active" onClick={this.props.cleanSkip}>
                                            {children.nameLiteral || <Translate id={children.name} /> }
                                            {children.beta && <Badge bsClass="menu-beta" pullRight={true}>BETA</Badge>}
                                        </Link>
                                    </li>
                                )
                            }) : null}
                        </ul>
                    </Collapse>
                </li>
            )
        } else {
            return (
                <li style={liStyle}>
                    <Link to={`${baseUrl}${url}`} activeClassName="active" onClick={this.props.cleanSkip}>
                        <FontAwesomeIcon icon={icon} />&nbsp;&nbsp;<Translate id={name} />
                        {alert && <Badge className="menu-badge" pullRight={true}><FontAwesomeIcon icon="bell" /></Badge>}
                    </Link>
                </li>
            )
        }
    }
}

Tab.propTypes = {
    tabIndex: PropTypes.string.isRequired,
    baseUrl: PropTypes.string.isRequired,
    url: PropTypes.string,
    icon: PropTypes.any.isRequired,
    name: PropTypes.string.isRequired,
    cleanSkip: PropTypes.func.isRequired,
    condition: PropTypes.bool,
    isSuperAdmin: PropTypes.bool.isRequired,
    collapse: PropTypes.bool,
    childrens: PropTypes.array,
    alert: PropTypes.bool
}

export default Tab
