import * as actions from '../actions'
import * as storage from '../../utils/storage'

const defaultState = null

export default function botSelected(state = defaultState, action) {
    switch (action.type) {
        case actions.SELECT_BOT:
            if (action.payload && action.payload !== state) {
                state = action.payload
                if (state && state.bot) {
                    storage.set('botSelected', state.bot)
                }
            }
            return state

        case actions.CLEAN_ALL:
        case actions.APP_SELECT:
            storage.remove('botSelected')
            return defaultState

        default:
            return state
    }
}
