// SENTRY
import * as Sentry from '@sentry/browser'
import { RewriteFrames } from '@sentry/integrations'
if (process.env.SENTRY_DSN) {
    Sentry.init({
        dsn: process.env.SENTRY_DSN,
        environment: process.env.MT_ENV,
        integrations: [
            new RewriteFrames()
        ],
        ignoreErrors: [
            'ChunkLoadError',
            'Loading chunk',
            'Out of memory',
            'ResizeObserver loop limit exceeded'
        ],
        beforeSend(event) {
            if (event && event.extra && event.extra.__serialized__ && event.extra.__serialized__.apiCall) {
                // IGNORE ALL UNHANDLED EXCEPTIONS FROM API CALL
                return null
            }
            return event
        }
    })
}

import 'core-js/stable' // instead of @babel/polyfill since Babel 7.4.0
import 'regenerator-runtime/runtime' // instead of @babel/polyfill since Babel 7.4.0
import React from 'react'
import store from './state/store'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { LocalizeProvider } from 'react-localize-redux'
import { IndexRoute, Router, Route, browserHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'
import loadable from "@loadable/component"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import 'bootstrap-daterangepicker/daterangepicker.css'
import 'react-widgets/styles.css'
import './less/react-toggle.less'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)

import Spinner from './components/common/Spinner'
const fallbackSpinner = { fallback: <Spinner /> }

import Base from './containers/Base'
import Manager from './containers/Manager'
import AppsContainer from './containers/AppsContainer'
import Dashboard from './components/Dashboard'

const AccountEdit = loadable(() => import('./components/AccountEdit'), fallbackSpinner)
const Accounts = loadable(() => import('./components/Accounts'), fallbackSpinner)
const AccountsContainer = loadable(() => import('./containers/AccountsContainer'), fallbackSpinner)
const AppAdd = loadable(() => import('./components/AppAdd'), fallbackSpinner)
const AppEdit = loadable(() => import('./components/AppEdit'), fallbackSpinner)
const AppThemeEdit = loadable(() => import('./components/AppThemeEdit'), fallbackSpinner)
const Apps = loadable(() => import('./components/Apps'), fallbackSpinner)
const Billing = loadable(() => import('./components/Billing'), fallbackSpinner)
const ConditionAddEdit = loadable(() => import('./components/AI/ConditionAddEdit'), fallbackSpinner)
const Conditions = loadable(() => import('./components/AI/Conditions'), fallbackSpinner)
const ConditionsContainer = loadable(() => import('./containers/AI/ConditionsContainer'), fallbackSpinner)
const Conversation = loadable(() => import('./containers/Conversation'), fallbackSpinner)
const Conversations = loadable(() => import('./containers/Conversations'), fallbackSpinner)
const Entities = loadable(() => import('./components/AI/Entities'), fallbackSpinner)
const Entity = loadable(() => import('./components/AI/Entity'), fallbackSpinner)
const Samples = loadable(() => import('./components/AI/Samples'), fallbackSpinner)
const HSMTemplates = loadable(() => import('./containers/HSMTemplates'), fallbackSpinner)
const HSMTemplatesAddEdit = loadable(() => import('./containers/HSMTemplatesAddEdit'), fallbackSpinner)
const HSMs = loadable(() => import('./containers/HSMs'), fallbackSpinner)
const Integrations = loadable(() => import('./components/Integrations'), fallbackSpinner)
const Stats = loadable(() => import('./components/Stats'), fallbackSpinner)
const V2CStats = loadable(() => import('./components/V2CStats'), fallbackSpinner)
const GupShupBalanceStats = loadable(() => import('./components/GupShupBalanceStats'), fallbackSpinner)
const Locales = loadable(() => import('./containers/Locales'), fallbackSpinner)
const Login = loadable(() => import('./components/Login'), fallbackSpinner)
const NLP = loadable(() => import('./components/AI/NLP'), fallbackSpinner)
const Releases = loadable(() => import('./containers/Releases'), fallbackSpinner)
const Reports = loadable(() => import('./containers/Reports'), fallbackSpinner)
const ReportsGoogleLookerStudio = loadable(() => import('./containers/ReportsGoogleLookerStudio'), fallbackSpinner)
const ReportsLeads = loadable(() => import('./containers/ReportsLeads'), fallbackSpinner)
const ResourceAddEdit = loadable(() => import('./components/ResourceAddEdit'), fallbackSpinner)
const ResourceContainer = loadable(() => import('./containers/ResourceContainer'), fallbackSpinner)
const Resources = loadable(() => import('./components/Resources'), fallbackSpinner)
const Status = loadable(() => import('./components/AI/Status'), fallbackSpinner)
const Task = loadable(() => import('./components/AI/Task'), fallbackSpinner)
const TaskCheck = loadable(() => import('./components/AI/TaskCheck'), fallbackSpinner)
const Tasks = loadable(() => import('./components/AI/Tasks'), fallbackSpinner)
const UnknownConversations = loadable(() => import('./components/AI/UnknownConversations'), fallbackSpinner)
const UnknownExpressions = loadable(() => import('./components/AI/UnknownExpressions'), fallbackSpinner)
const UserAdd = loadable(() => import('./components/UserAdd'), fallbackSpinner)
const UserDetail = loadable(() => import('./components/UserDetail'), fallbackSpinner)
const UserEdit = loadable(() => import('./components/UserEdit'), fallbackSpinner)
const Users = loadable(() => import('./components/Users'), fallbackSpinner)
const UsersContainer = loadable(() => import('./containers/UsersContainer'), fallbackSpinner)
const Support = loadable(() => import('./containers/Support'), fallbackSpinner)

const OperatorsContainer = loadable(() => import('./containers/OperatorsContainer'), fallbackSpinner)
const Operators = loadable(() => import('./components/Operators'), fallbackSpinner)

const rootElement = document.getElementById('root')
const history = syncHistoryWithStore(browserHistory, store)

render(
    <Provider store={store}>
        <LocalizeProvider store={store}>
            <Router history={history} onUpdate={() => window.scrollTo(0, 0)}>
                <Route path="/manager" component={ Base }>
                    <Route path="login" component={ Login }/>
                    <Route path="app" component={ AppsContainer }>
                        <IndexRoute component={ Apps }/>
                        <Route path="add" component={ AppAdd }/>
                        <Route path="edit/:appId" component={ AppEdit }/>
                        <Route path="edit/theme/:appId" component={ AppThemeEdit }/>
                    </Route>
                    <Route path="billing/:appCodename" component={ Billing }/>
                    <Route path="stats" component={ Stats }/>
                    <Route path="v2c" component={V2CStats} />
                    <Route path="gupshupBalance" component={GupShupBalanceStats} />
                    <Route path="account/:appCodename" component={ AccountsContainer }>
                        <IndexRoute component={ Accounts }/>
                        <Route path="edit/:accountId" component={ AccountEdit }/>
                    </Route>
                    <Route path=":appCodename" component={ Manager }>
                        <IndexRoute component={ Dashboard }/>
                        <Route path="dashboard" component={ Dashboard }/>
                        <Route path="reports/:section" component={ Reports }/>
                        <Route path="user" component={ UsersContainer }>
                            <IndexRoute component={ Users }/>
                            <Route path="edit/:userId" component={ UserEdit }/>
                            <Route path="add" component={ UserAdd }/>
                            <Route path=":userId" component={ UserDetail }/>
                        </Route>
                        <Route path="conversation" component={ Conversations }>
                            <Route path=":messageUserId" component={ Conversation }/>
                        </Route>
                        <Route path="ai/condition" component={ ConditionsContainer }>
                            <IndexRoute component={ Conditions }/>
                            <Route path="add" component={ ConditionAddEdit }/>
                            <Route path="edit/:conditionId" component={ ConditionAddEdit }/>
                        </Route>
                        <Route path="ai/train" component={ NLP } />
                        <Route path="ai/entities" component={ Entities } />
                        <Route path="ai/entities/:entityId" component={ Entity } />
                        <Route path="ai/samples/:entityId/:value" component={ Samples } />
                        <Route path="ai/task" component={ Tasks } />
                        <Route path="ai/task/:taskId" component={ Task } />
                        <Route path="ai/task/:taskId/check" component={ TaskCheck } />
                        <Route path="ai/status" component={ Status } />
                        <Route path="ai/unknownexpression" component={ UnknownExpressions } />
                        <Route path="ai/unknownexpression/:expressionId" component={ UnknownConversations } />
                        <Route path="operator" component={OperatorsContainer}>
                            <IndexRoute component={Operators} />
                        </Route>
                        <Route path="support" component={ Support }/>
                        <Route path="integrations" component={ Integrations }/>
                        <Route path="resources" component={ ResourceContainer }>
                            <IndexRoute component={ Resources }/>
                            <Route path="add" component={ ResourceAddEdit }/>
                            <Route path="edit/:resourceId" component={ ResourceAddEdit }/>
                        </Route>
                        <Route path="hsm" component={ HSMs } />
                        <Route path="hsm/template" component={ HSMTemplates } />
                        <Route path="hsm/template/add" component={ HSMTemplatesAddEdit } />
                        <Route path="hsm/template/edit/:hsmTemplateId" component={ HSMTemplatesAddEdit } />
                        <Route path="release" component={ Releases }/>
                        <Route path="locale" component={ Locales } />
                        <Route path="report/googlelookerstudio" component={ ReportsGoogleLookerStudio } />
                        <Route path="report/leads" component={ ReportsLeads } />
                    </Route>
                </Route>
            </Router>
        </LocalizeProvider>
    </Provider>,
    rootElement
)
