import * as actions from '../../actions'
import * as storage from '../../../utils/storage'

const defaultState = null

export default function witSelected(state = defaultState, action) {
    switch (action.type) {
        case actions.SELECT_WIT:
            if (action.payload && action.payload !== state) {
                state = action.payload
                if (state && state.language) {
                    storage.set('witSelected', state.language)
                }
            }
            return state

        case actions.CLEAN_ALL:
        case actions.APP_SELECT:
            storage.remove('witSelected')
            return defaultState

        default:
            return state
    }
}
