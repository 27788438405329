import * as actions from '../actions'

var defaultState = null;

export default function webhooks(state = defaultState, action) {
    switch (action.type) {

        case actions.WEBHOOK_GET:
            var newState = state;
            if (!action.error) {
                newState = action.payload.data;
            }
            return newState;

        case actions.WEBHOOK_CLEAN:
            return defaultState;

        default:
            return state;

    }
}
