import * as actions from '../actions';

var defaultState = null;

export default function messageUser(state = defaultState, action) {
    switch (action.type) {

        case actions.MESSAGE_USER_GET_ONE:
            var newState = state;
            if (!action.error) {
                newState = action.payload.data;
            }
            return newState;

        default:
            return state;

    }
}
