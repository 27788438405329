import * as actions from '../actions'
import moment from 'moment'
import reverse from 'lodash/reverse'
import concat from 'lodash/concat'
import uniqBy from 'lodash/uniqBy'

const defaultState = []

export default function messages(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_ALL:
        case actions.APP_SELECT:
        case actions.CLEAN_MESSAGES:
            return defaultState

        case actions.GET_MESSAGES:
            if (!action.error) {
                state = action.payload.config.params.sort.createdAt === 1 ? action.payload.data.objects : reverse(action.payload.data.objects)
            }
            return state

        case actions.GET_OLD_MESSAGES:
            if (action && action.payload && action.payload.data && action.payload.data.objects && action.payload.data.objects.length) {
                state = concat(action.payload.data.objects, state)
                state = uniqBy(state, '_id')
            }
            return state.slice(0)

        case actions.GET_NEW_MESSAGES:
            if (action && action.payload && action.payload.data && action.payload.data.objects && action.payload.data.objects.length) {
                state = concat(state, action.payload.data.objects)
                state = uniqBy(state, '_id')
            }
            return state.slice(0)

        case actions.RECEIVE_MESSAGE:
            if (state && state.length && !state.find(message => message._id === action.payload._id)) {
                state.push(action.payload)
            }
            return state.slice(0)

        case actions.UPDATE_MESSAGE:
            if (state && state.length) {
                state = state.map(message => {
                    if (message._id === action.payload._id) {
                        message = Object.assign(message, action.payload)
                    }
                    return message
                })
            }
            return state.slice(0).filter(message => !message.deletedAt)

        case actions.SPEECH_TO_TEXT:
            if (!action.error && state && state.length) {
                state = state.map(message => {
                    if (message._id === action.payload.data.message._id) {
                        message = Object.assign(message, action.payload.data.message)
                    }
                    return message
                })
            }
            return state.slice(0)

        default:
            return state
    }
}
