import * as actions from '../actions'
const defaultState = null

export default function botsStats(state = defaultState, action) {
    let newState = Object.assign({}, state)
    if (!newState) {
        newState = {}
    }
    switch (action.type) {
        case actions.BOTS_STATS_GET:
            if (action && !action.error && action.payload && action.payload.data) {
                newState.updated = new Date()
                newState.stats = action.payload.data.stats
            }
            return newState

        default:
            return state
    }
}
