import * as actions from '../actions'

const defaultState = null

export default function account(state = defaultState, action) {
    switch (action.type) {
        case actions.APP_SELECT:
            return action.payload

        case actions.ACCOUNT_GET_ONE:
        {
            let newState = state
            if (!action.error) {
                newState = action.payload.data
            }
            return newState
        }

        case actions.ACCOUNT_ADD:
        {
            let newState = state
            if (!action.error) {
                newState = defaultState
            }
            return newState
        }

        case actions.ACCOUNT_CLEAN_ONE:
            return defaultState

        default:
            return state
    }
}
