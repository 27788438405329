import * as actions from '../../actions'

var defaultState = null

export default function tasks(state = defaultState, action) {
    switch (action.type) {

        case actions.GET_AI_TASKS:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.CLEAN_AI_TASKS:
            return defaultState

        default:
            return state

    }
}
