import * as actions from '../actions'

const defaultState = null

export default function bots(state = defaultState, action) {
    switch (action.type) {
        case actions.BOTS_GET:
            let newState = state
            if (!action.error) {
                newState = action.payload && action.payload.data && action.payload.data.objects || []
            }
            return newState

        case actions.BOTS_CLEAN:
            return defaultState

        default:
            return state
    }
}
