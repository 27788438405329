import * as actions from '../actions'

var defaultState = null

export default function resourceUploadMedia(state = defaultState, action) {
    switch (action.type) {
        case actions.RESOURCE_UPLOAD_MEDIA:
            var newState = state
            if (!action.error) {
                newState = defaultState
            }
            return newState
        case actions.RESOURCE_UPLOAD_MEDIA_CLEAN:
            return defaultState
        default:
            return state
    }
}
