import * as actions from '../actions'
import * as storage from '../../utils/storage'

const defaultState = null

export default function profile(state = defaultState, action) {
    switch (action.type) {
        case actions.LOGIN_USER:
            if (action.payload && action.payload.data && action.payload.data.profile > 0) {
                storage.set(storage.accessTokenKey, action.payload.data.token)
                state = action.payload.data
                state._id = state.id
                delete state.token
                delete state.id
            } else {
                storage.remove(storage.accessTokenKey)
                state = defaultState
            }
            return state

        case actions.USER_GET_PROFILE:
            if (!action.error) {
                state = action.payload.data
            }
            return state

        case actions.USER_CLEAN_PROFILE:
            storage.remove(storage.accessTokenKey)
            return defaultState

        default:
            if (action.payload && action.payload.error && action.payload.error.response && action.payload.error.response.status && action.payload.error.response.status === 401) {
                state = defaultState
            }
            return state
    }
}
