import * as actions from '../actions'
const defaultState = {}
export default function customerService(state = defaultState, action) {
    switch (action.type) {
        case actions.CLEAN_CUSTOMER:
            return defaultState
        case actions.GET_CUSTOMER: {
            if (action.payload && action.payload.data) {
                state.data = action.payload.data
            }
            return state
        }
        case actions.AVAILABILITY_CUSTOMER: {
            if (action.payload && action.payload.data && action.payload.data) {
                state.availability = action.payload.data
            }
            return state
        }
        case actions.GET_ORIGINS_CUSTOMER: {
            if (action.payload && action.payload.data && action.payload.data.objects) {
                state.origins = action.payload.data.objects
            }
            return state
        }
        case actions.GET_OPERATOR_CUSTOMER: {
            if (action.payload && action.payload.data.objects) {
                state.operators = action.payload.data.objects
            }
            return state
        }
        default:
            return state
    }
}
